:root {
    --primary-color: #4a90e2;
    --secondary-color: #50c878;
    --text-color: #ffffff;
    --hover-color: #f0f0f0;
    --transition-speed: 0.3s;
  }
  
  .navbar {
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }
  
  .navbar-logo {
    color: var(--text-color);
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    transition: all var(--transition-speed) ease;
  }
  
  .navbar-logo:hover {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin-left: 20px;
  }
  
  .nav-link {
    color: var(--text-color);
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 25px;
    transition: all var(--transition-speed) ease;
  }
  
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }
  
  .menu-icon span {
    width: 2rem;
    height: 0.25rem;
    background: var(--text-color);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  
  @media screen and (max-width: 768px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 70px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: linear-gradient(180deg, var(--primary-color) 0%, var(--secondary-color) 100%);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-item {
      margin: 0;
      padding: 1rem;
      width: 100%;
      text-align: center;
    }
  
    .nav-link {
      font-size: 1.5rem;
      padding: 15px;
      display: block;
    }
  
    .menu-icon {
      display: flex;
    }
  
    .menu-icon.open span:nth-child(1) {
      transform: rotate(45deg);
    }
  
    .menu-icon.open span:nth-child(2) {
      opacity: 0;
    }
  
    .menu-icon.open span:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
  
  /* Add this for a subtle animation when menu items appear on mobile */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @media screen and (max-width: 768px) {
    .nav-menu.active .nav-item {
      animation: fadeIn 0.5s ease forwards;
      opacity: 0;
    }
  
    .nav-menu.active .nav-item:nth-child(1) { animation-delay: 0.1s; }
    .nav-menu.active .nav-item:nth-child(2) { animation-delay: 0.2s; }
    .nav-menu.active .nav-item:nth-child(3) { animation-delay: 0.3s; }
    /* Add more if you have more menu items */
  }