:root {
  --primary-color: #50c878;
  --secondary-color: #4a90e2;
  --text-color: #ffffff;
  --hover-color: #f0f0f0;
  --transition-speed: 0.3s;
}

.footer-bottom {
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: var(--text-color);
  padding: .4rem 0;
  font-size: 1rem;
  text-align: center; /* Center the text */
}
