.quiz-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.subject {
  font-weight: bold;
  font-size: 18px;
}

.score {
  font-size: 18px;
}

.question-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.question h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
  font-size: 18px; /* Initial font size */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  color: #333;
}

.option:not(:disabled):not(.correct):not(.incorrect):hover {
  background-color: #dcc3fd;
}

.option:not(.correct):not(.incorrect):hover {
  background-color: #dcc3fd;
}

.option.correct {
  background-color: #bfecca;
  border-color: #b4e5c0;
}

.option.incorrect {
  background-color: #f7abb1;
  border-color: #feb8bf;
  color: #721c24;
}

.option:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.option.correct:disabled,
.option.incorrect:disabled {
  opacity: 1;
}

.quiz-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.next-question,
.quit-quiz {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.next-question {
  background-color: #8a2be2;
  color: white;
}

.next-question:disabled {
  background-color: #d8bfd8;
  cursor: not-allowed;
}

.quit-quiz {
  background-color: #ff6b6b;
  color: white;
}

.mute-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.font-size-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.font-size-controls button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}
